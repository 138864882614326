import type {ReactNode} from 'react';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';

interface RadioGroupOption {
  id: string;
  label: string;
  value: string;
  checked?: boolean;
  className?: string;
}

interface RadioGroupProps extends React.HTMLProps<HTMLElement> {
  name: string;
  className?: string;
  options: RadioGroupOption[];
  'aria-description'?: string;
  'aria-describedby'?: string;
  error?: any;
  helpText?: string | ReactNode;
  hint?: string | ReactNode;
  id: string;
  label?: string;
  labelClassName?: string;
  inline?: boolean;
}

export default function RadioGroup({
  name,
  error,
  helpText,
  hint,
  id,
  label = '',
  className = '',
  labelClassName = '',
  options,
  inline = false,
  ...inputProps
}: RadioGroupProps) {
  const errorId = `${id}-error`;
  const hintId = `${id}-hint`;
  const helpTextId = `${id}-help-text`;
  const describedBy = cn(inputProps['aria-describedby'], {
    [errorId]: error,
    [hintId]: hint,
    [helpTextId]: helpText,
  });

  let errorText;
  const errorType = typeof error;
  if (errorType === 'string') {
    errorText = error;
  } else if (errorType === 'object' && error?.errorMessage) {
    errorText = error.errorMessage;
  }

  const errorProps = errorText
    ? {
        dangerouslySetInnerHTML: {__html: errorText},
      }
    : {children: error};

  const optionsConditionalClasses = inline
    ? 'inline-flex items-center space-x-6'
    : 'flex-col space-y-3';

  return (
    <fieldset className={cn('mb-4 flex-1 text-left space-y-3', className)}>
      <legend className={labelClassName}>{label}</legend>
      <div className={cn('p-radio__input', optionsConditionalClasses)}>
        {options.map((option) => (
          <div className="flex items-center" key={option.id}>
            <input
              aria-describedby={describedBy}
              className={twMerge('h-4 w-4 accent-black', className)}
              type="radio"
              id={option.id}
              name={name}
              value={option.value}
              defaultChecked={option.checked}
            />

            <label className="pl-2 cursor-pointer" htmlFor={option.id}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {error ? (
        <span
          className="shadow-[0_0_0_2px] shadow-legacy-pink-70 text-white bg-legacy-pink-70 block py-[0.4em] px-[0.9em] text-xs font-medium rounded-[0_0_4px_4px] leading-[1.6em]"
          id={errorId}
          {...errorProps}
        />
      ) : (
        hint && (
          <span
            className="shadow-[0_0_0_2px] shadow-legacy-cyan-20 text-black bg-legacy-cyan-20 block py-[0.4em] px-[0.9em] text-xs font-medium rounded-[0_0_4px_4px] leading-[1.6em]"
            id={hintId}
          >
            {hint}
          </span>
        )
      )}
      {helpText && (
        <span
          className="py-0 px-[0.9em] my-4 mx-0 block leading-[1.6em]"
          id={helpTextId}
        >
          {helpText}
        </span>
      )}
    </fieldset>
  );
}
